.formModal {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 4px 16px rgba(14, 41, 64, 0.2))
    drop-shadow(0px 2px 4px rgba(14, 41, 64, 0.2));
  width: 840px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
  min-height: 0;
}
@media (max-width: 1024px) {
  .formModal {
    max-width: 840px;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .formModal {
    width: 100%;
    min-width: 0;
  }
}
.fill {
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 2px 6px rgba(14, 41, 64, 0.1))
    drop-shadow(0px 1px 2px rgba(14, 41, 64, 0.059));
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-bottom: 48px;
}
.fill > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.fill > :global(.__wab_flex-container) > *,
.fill > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.fill > :global(.__wab_flex-container) > picture > img,
.fill > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .fill {
    padding: 0px;
  }
}
@media (max-width: 1024px) {
  .fill > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 1024px) {
  .fill > :global(.__wab_flex-container) > *,
  .fill > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .fill > :global(.__wab_flex-container) > picture > img,
  .fill
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.top {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.header {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 48px;
}
.header > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (max-width: 1024px) {
  .header {
    padding: 32px 32px 0px;
  }
}
.left {
  height: 36px;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.left > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.left > :global(.__wab_flex-container) > *,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.left > :global(.__wab_flex-container) > picture > img,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.link {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  position: relative;
}
.dialogClose:global(.__wab_instance) {
  position: relative;
  top: auto;
  right: auto;
  opacity: 1;
  max-width: 100%;
  object-fit: cover;
  border-radius: 999px;
}
.modalCloseButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.intro {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 16px;
}
.intro > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.intro > :global(.__wab_flex-container) > *,
.intro > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.intro > :global(.__wab_flex-container) > picture > img,
.intro > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
@media (max-width: 1024px) {
  .intro {
    padding: 16px 32px 0px;
  }
}
.title {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-vF1ggEL4NG72);
  display: block;
  align-self: stretch;
  position: relative;
}
.desc {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.send:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.contactForm:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.form {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
}
.form > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .form {
    padding: 64px 32px;
  }
}
.inputsRow {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.inputsRow > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.inputsRow > :global(.__wab_flex-container) > *,
.inputsRow > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.inputsRow > :global(.__wab_flex-container) > picture > img,
.inputsRow
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 1024px) {
  .inputsRow {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .inputsRow > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (max-width: 1024px) {
  .inputsRow > :global(.__wab_flex-container) > *,
  .inputsRow > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .inputsRow > :global(.__wab_flex-container) > picture > img,
  .inputsRow
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .inputsRow > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .inputsRow > :global(.__wab_flex-container) > *,
  .inputsRow > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .inputsRow > :global(.__wab_flex-container) > picture > img,
  .inputsRow
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.nameWorkCol {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.nameWorkCol > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.nameWorkCol > :global(.__wab_flex-container) > *,
.nameWorkCol > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nameWorkCol > :global(.__wab_flex-container) > picture > img,
.nameWorkCol
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .nameWorkCol {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .nameWorkCol > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.nameProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.nameInput {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.nameInput > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.nameInput > :global(.__wab_flex-container) > *,
.nameInput > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nameInput > :global(.__wab_flex-container) > picture > img,
.nameInput
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.nameLabel {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.name:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  flex-shrink: 0;
}
.svg__zwRad {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.nameErrorContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  min-width: 0;
  flex-shrink: 0;
}
.error {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-pmZ4Vyx2r26Q);
  display: block;
  align-self: stretch;
  position: relative;
}
.emailProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.emailInput {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.emailInput > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.emailInput > :global(.__wab_flex-container) > *,
.emailInput > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.emailInput > :global(.__wab_flex-container) > picture > img,
.emailInput
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.emailLabel {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  flex-shrink: 0;
}
.svg__qsDjn {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.emailErrorContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  min-width: 0;
  flex-shrink: 0;
}
.error2 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-pmZ4Vyx2r26Q);
  display: block;
  align-self: stretch;
  position: relative;
}
.institutionPhoneCol {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
}
.institutionPhoneCol > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.institutionPhoneCol > :global(.__wab_flex-container) > *,
.institutionPhoneCol
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.institutionPhoneCol > :global(.__wab_flex-container) > picture > img,
.institutionPhoneCol
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .institutionPhoneCol {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .institutionPhoneCol > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.institutionProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.institutionInput {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.institutionInput > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.institutionInput > :global(.__wab_flex-container) > *,
.institutionInput > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.institutionInput > :global(.__wab_flex-container) > picture > img,
.institutionInput
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.institutionLabels {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.institutionLabels > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.institutionLabels > :global(.__wab_flex-container) > *,
.institutionLabels > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.institutionLabels > :global(.__wab_flex-container) > picture > img,
.institutionLabels
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.label4 {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  flex-grow: 1;
  position: relative;
}
.label5 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-sJr9sbZwbyqK);
  display: block;
  flex-grow: 1;
  text-align: right;
  position: relative;
}
.institution:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  flex-shrink: 0;
}
.svg__nwEcm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.institutionErrorContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  min-width: 0;
  flex-shrink: 0;
}
.error5 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-pmZ4Vyx2r26Q);
  display: block;
  align-self: stretch;
  position: relative;
}
.phoneProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.phoneInput {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.phoneInput > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.phoneInput > :global(.__wab_flex-container) > *,
.phoneInput > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.phoneInput > :global(.__wab_flex-container) > picture > img,
.phoneInput
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.label6 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.label6 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.label6 > :global(.__wab_flex-container) > *,
.label6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.label6 > :global(.__wab_flex-container) > picture > img,
.label6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.label7 {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  flex-grow: 1;
  position: relative;
}
.label8 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-sJr9sbZwbyqK);
  display: block;
  flex-grow: 1;
  text-align: right;
  position: relative;
}
.phone:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 40px;
  flex-shrink: 0;
}
.svg__bIMws {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.phoneErrorContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  min-width: 0;
  flex-shrink: 0;
}
.error6 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-pmZ4Vyx2r26Q);
  display: block;
  align-self: stretch;
  position: relative;
}
.messageProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.messageTextarea {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.messageTextarea > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.messageTextarea > :global(.__wab_flex-container) > *,
.messageTextarea > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.messageTextarea > :global(.__wab_flex-container) > picture > img,
.messageTextarea
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.messageLabel {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.messageLabel > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.messageLabel > :global(.__wab_flex-container) > *,
.messageLabel > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.messageLabel > :global(.__wab_flex-container) > picture > img,
.messageLabel
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.label10 {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  flex-grow: 1;
  position: relative;
}
.message:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 144px;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .message:global(.__wab_instance) {
    height: 144px;
    flex-shrink: 0;
  }
}
@media (max-width: 576px) {
  .message:global(.__wab_instance) {
    height: 320px;
    flex-shrink: 0;
  }
}
.messageErrorContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
  min-width: 0;
  flex-shrink: 0;
}
.error3 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--token-pmZ4Vyx2r26Q);
  display: block;
  align-self: stretch;
  position: relative;
}
.sendMessageBtn:global(.__wab_instance) {
  height: 48px;
  position: relative;
  width: 170px;
  flex-shrink: 0;
}
.svg__pIs5C {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__invbI {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-9T9NC2lhv1Ej);
}
.spinnerIcon:global(.__wab_instance) {
  max-width: 100%;
  width: 20px;
  height: 20px;
}
.svg__osSyw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.footer {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  background: linear-gradient(rgba(249, 250, 251, 1), rgba(249, 250, 251, 1)),
    url("https://site-assets.plasmic.app/3648a9660ccc75f47048e2740948dc57.jpg")
      0% 0% / 325px 325px repeat,
    rgba(255, 255, 255, 1);
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  height: auto;
  min-width: 0;
  padding: 48px;
}
.footer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
}
.footer > :global(.__wab_flex-container) > *,
.footer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.footer > :global(.__wab_flex-container) > picture > img,
.footer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
@media (max-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 64px 32px;
  }
}
@media (max-width: 1024px) {
  .footer > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 64px);
    height: calc(100% + 64px);
  }
}
@media (max-width: 1024px) {
  .footer > :global(.__wab_flex-container) > *,
  .footer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .footer > :global(.__wab_flex-container) > picture > img,
  .footer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 64px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer {
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.sales {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
}
.sales > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.sales > :global(.__wab_flex-container) > *,
.sales > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sales > :global(.__wab_flex-container) > picture > img,
.sales > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (max-width: 1024px) {
  .sales {
    height: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sales {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sales > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.icon2 {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--token-7u3Fjf0ZO4pV);
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
}
@media (max-width: 1024px) {
  .icon2 {
    display: flex;
  }
}
.img__onE5R {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.img__onE5R > picture > img {
  object-fit: cover;
}
.text8 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: column;
}
.text8 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.text8 > :global(.__wab_flex-container) > *,
.text8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text8 > :global(.__wab_flex-container) > picture > img,
.text8 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .text8 {
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .text8 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text8 {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.title2 {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.paragraph {
  display: flex;
  position: relative;
  flex-direction: column;
}
.paragraph > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.paragraph > :global(.__wab_flex-container) > *,
.paragraph > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.paragraph > :global(.__wab_flex-container) > picture > img,
.paragraph
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.desc2 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.link__bzIj5 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.link__flqLt {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.desc3 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.button2:global(.__wab_instance) {
  height: 32px;
  position: relative;
  flex-shrink: 0;
}
.svg___7GFtB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text___2ZFhw {
  color: var(--token-x0ltlSxVUZco);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.svg__cjTlV {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.support {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.support > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.support > :global(.__wab_flex-container) > *,
.support > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.support > :global(.__wab_flex-container) > picture > img,
.support
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .support {
    height: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 1024px) {
  .support > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 32px);
    width: calc(100% + 32px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 1024px) {
  .support > :global(.__wab_flex-container) > *,
  .support > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .support > :global(.__wab_flex-container) > picture > img,
  .support
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 32px;
    margin-top: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .support {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .support > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.icon4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--token-7u3Fjf0ZO4pV);
  flex-shrink: 0;
  border-radius: 8px;
}
@media (max-width: 1024px) {
  .icon4 {
    display: flex;
  }
}
.img__guQs {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.img__guQs > picture > img {
  object-fit: cover;
}
.text9 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: column;
}
.text9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.text9 > :global(.__wab_flex-container) > *,
.text9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text9 > :global(.__wab_flex-container) > picture > img,
.text9 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .text9 {
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .text9 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text9 {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.title5 {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.paragraph2 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.paragraph2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.paragraph2 > :global(.__wab_flex-container) > *,
.paragraph2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.paragraph2 > :global(.__wab_flex-container) > picture > img,
.paragraph2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.desc5 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.link__qvVkD {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.desc6 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.link__go6Jr {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.link__rtLxE {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.desc7 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.company {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 0;
  flex-direction: row;
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
  min-height: 0;
}
.company > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.company > :global(.__wab_flex-container) > *,
.company > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.company > :global(.__wab_flex-container) > picture > img,
.company
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 1024px) {
  .company {
    height: auto;
    flex-shrink: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .company {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .company > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.icon5 {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--token-7u3Fjf0ZO4pV);
  flex-shrink: 0;
  border-radius: 8px;
}
@media (max-width: 1024px) {
  .icon5 {
    display: flex;
  }
}
.img__pwiLs {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.img__pwiLs > picture > img {
  object-fit: cover;
}
.text {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 35%;
}
.text > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .text {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .text > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.title4 {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.paragraph3 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.paragraph3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.paragraph3 > :global(.__wab_flex-container) > *,
.paragraph3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.paragraph3 > :global(.__wab_flex-container) > picture > img,
.paragraph3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.desc4 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.desc8 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.desc9 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.link__mliyE {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.link__kugEl {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.icon3 {
  background: rgba(223, 228, 233, 1);
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 8px;
}
.icon3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.icon3 > :global(.__wab_flex-container) > *,
.icon3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.icon3 > :global(.__wab_flex-container) > picture > img,
.icon3 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.layout112 {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.svg__gw4Rc {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #59626b;
  transform: translate(0.25px, -0.25px);
  width: 19.5px;
  height: 22.5px;
  display: block;
  top: 1px;
  left: 2px;
}
