.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 32px;
  justify-self: flex-start;
  height: 32px;
}
.rootdisabled {
  justify-content: center;
  align-items: center;
}
.spinnerIcon {
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: auto;
  min-height: none;
}
.spinnerIcon > picture > img {
  object-fit: cover;
}
.spinnerIcondisabled {
  display: none !important;
}
.spinnerIcon2 {
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: auto;
  min-height: none;
}
.spinnerIcon2 > picture > img {
  object-fit: cover;
}
.spinnerIcon2light {
  display: block !important;
}
.spinnerIcon3 {
  object-fit: cover;
  max-width: 100%;
  color: var(--token-sJr9sbZwbyqK);
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: none;
}
.spinnerIcon3disabled {
  display: block;
}
