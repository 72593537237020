.root {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.dialogCore:global(.__wab_instance) {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-backdrop-filter: blur(10px);
}
.pcls_qgYIZ0zRoEYk {
  background: #000000cc;
  outline: none;
}
.dialogContent:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  min-width: 0;
  min-height: 0;
}
.hiddenIfEmptyContainer:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.dialogTitle:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.openProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  left: auto;
  top: auto;
}
.overlayMask {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: none;
  width: 100%;
  align-self: flex-start;
  left: auto;
  top: auto;
  height: 100%;
  cursor: pointer;
  min-width: 0;
  min-height: 0;
  border-radius: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .overlayMask {
    left: auto;
    top: auto;
  }
}
@media (max-width: 576px) {
  .overlayMask {
    left: auto;
    top: auto;
  }
}
.freeBox {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: white;
  width: 100%;
  align-self: flex-end;
  left: auto;
  top: auto;
  z-index: 1;
  height: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 4;
  margin: 40px 24px;
}
.contactModalForm:global(.__wab_instance) {
  position: fixed;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.contactModalMessageSent:global(.__wab_instance) {
  position: fixed;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  z-index: 1;
}
