.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  position: relative;
  padding: 0px;
  border-width: 0px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-vF1ggEL4NG72);
  width: 24px;
  height: 24px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
}
.root .svg:hover {
  color: var(--token-pkN2y4iq-viG);
}
