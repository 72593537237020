.textarea {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  background: #ffffff;
  width: 100%;
  height: auto;
  outline-style: none;
  min-width: 0;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid var(--token-NagwnEgqYDVz);
}
.textareasize_small {
  padding: 4px 16px;
}
.textareainvalid {
  background: var(--token-f0Mcnp3uZyQy);
  border-color: var(--token-JYEEwSwHupuU);
}
.textareadisabled {
  background: var(--token-7u3Fjf0ZO4pV);
}
.textarea:hover {
  border-color: var(--token-3hsdHs9SQrQP);
}
.textarea:focus-within {
  box-shadow: inset 0px 1px 4px 2px #0f2a410d;
  outline: none;
  border-color: var(--token-pkN2y4iq-viG);
}
.textareainvalid:hover {
  border-color: var(--token-pmZ4Vyx2r26Q);
}
.textareainvalid:focus-within {
  outline: none;
  border-color: var(--token-pmZ4Vyx2r26Q);
}
.textareadisabled:hover {
  border-color: var(--token-NagwnEgqYDVz);
}
.textareadisabled:focus-within {
  box-shadow: none;
  outline: none;
  border-color: var(--token-NagwnEgqYDVz);
}
