.root {
  display: flex;
  position: relative;
  flex-direction: row;
  background: var(--token-pkN2y4iq-viG);
  cursor: pointer;
  transition-property: background;
  transition-duration: 0.1s;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.1s;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.rootisDisabled {
  cursor: not-allowed;
  opacity: 1;
  background: var(--token-NagwnEgqYDVz);
  justify-self: flex-start;
}
.rootsize_small {
  padding-top: 8px;
  padding-right: 16px;
  padding-left: 16px;
  height: auto;
}
.rootsize_medium {
  padding: 0px;
}
.rootsize_large {
  height: auto;
  width: auto;
  justify-self: flex-start;
  display: inline-flex;
  padding: 14px 28px;
}
.rootcolor_primary {
  background: var(--token-pkN2y4iq-viG);
  justify-self: flex-start;
}
.rootcolor_sky {
  background: var(--token-F-isEiSy-7QO);
  justify-self: flex-start;
}
.rootcolor_white {
  background: var(--token-9T9NC2lhv1Ej);
  justify-self: flex-start;
}
.rootcolor_cta {
  background: var(--token-pmZ4Vyx2r26Q);
  justify-self: flex-start;
}
.rootcolor_gray {
  background: var(--token-7u3Fjf0ZO4pV);
  justify-self: flex-start;
}
.rootisDisabled_color_primary {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled_color_sky {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_sky > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_sky > :global(.__wab_flex-container) > *,
.rootisDisabled_color_sky
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_sky > :global(.__wab_flex-container) > picture > img,
.rootisDisabled_color_sky
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_color_white {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_white > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_white > :global(.__wab_flex-container) > *,
.rootisDisabled_color_white
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_white > :global(.__wab_flex-container) > picture > img,
.rootisDisabled_color_white
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootcolor_cta_isDisabled {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootcolor_cta_isDisabled > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootcolor_cta_isDisabled > :global(.__wab_flex-container) > *,
.rootcolor_cta_isDisabled
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootcolor_cta_isDisabled > :global(.__wab_flex-container) > picture > img,
.rootcolor_cta_isDisabled
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_color_gray {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_gray > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_gray > :global(.__wab_flex-container) > *,
.rootisDisabled_color_gray
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_gray > :global(.__wab_flex-container) > picture > img,
.rootisDisabled_color_gray
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_size_medium_color_sky {
  padding: 0px;
}
.rootsize_large_isDisabled_color_sky {
  padding: 14px 28px;
}
.rootisDisabled_color_white_size_medium {
  padding: 0px;
}
.rootcolor_cta_isDisabled_size_medium {
  padding: 0px;
}
.rootsize_large_isDisabled_color_white {
  padding: 14px 28px;
}
.rootisDisabled_color_gray_size_medium {
  padding: 0px;
}
.rootcolor_cta_size_large_isDisabled {
  padding: 14px 28px;
}
.rootsize_large_isDisabled_color_gray {
  padding: 14px 28px;
}
.root:hover {
  background: #282826;
}
.root:active {
  background: #2e2e2b;
}
.rootisDisabled:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled:active {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_primary:hover {
  background: var(--token-gaowRD3VsD3w);
}
.rootcolor_primary:active {
  background: var(--token-gaowRD3VsD3w);
  box-shadow: inset 0px 1px 4px 2px #0f2a4133;
}
.rootcolor_sky:hover {
  background: var(--token-eqvPWoOG9mbc);
}
.rootcolor_sky:active {
  background: var(--token-eqvPWoOG9mbc);
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
}
.rootcolor_white:hover {
  background: var(--token-9T9NC2lhv1Ej);
}
.rootcolor_white:active {
  background: var(--token-9T9NC2lhv1Ej);
  box-shadow: inset 0px 1px 4px 2px #0f2a410d;
}
.rootcolor_cta:hover {
  background: var(--token-6ihMHiXSX_i2);
}
.rootcolor_cta:active {
  background: var(--token-6ihMHiXSX_i2);
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
}
.rootcolor_gray:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_gray:active {
  background: var(--token-NagwnEgqYDVz);
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
}
.rootisDisabled_color_primary:hover {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_primary:hover > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_primary:hover > :global(.__wab_flex-container) > *,
.rootisDisabled_color_primary:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_primary:hover
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootisDisabled_color_primary:hover
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_color_primary:active {
  background: var(--token-NagwnEgqYDVz);
  box-shadow: none;
}
.rootisDisabled_color_sky:active {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  box-shadow: none;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_sky:active > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_sky:active > :global(.__wab_flex-container) > *,
.rootisDisabled_color_sky:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_sky:active
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootisDisabled_color_sky:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_color_sky:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled_color_white:active {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  box-shadow: none;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_white:active > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_white:active > :global(.__wab_flex-container) > *,
.rootisDisabled_color_white:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_white:active
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootisDisabled_color_white:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootcolor_cta_isDisabled:active {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  box-shadow: none;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootcolor_cta_isDisabled:active > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootcolor_cta_isDisabled:active > :global(.__wab_flex-container) > *,
.rootcolor_cta_isDisabled:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootcolor_cta_isDisabled:active
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootcolor_cta_isDisabled:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_color_gray:active {
  background: var(--token-NagwnEgqYDVz);
  opacity: 1;
  cursor: not-allowed;
  flex-direction: row;
  box-shadow: none;
  border-radius: 64px;
  padding: 8px 16px;
  border-width: 0px;
}
.rootisDisabled_color_gray:active > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootisDisabled_color_gray:active > :global(.__wab_flex-container) > *,
.rootisDisabled_color_gray:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.rootisDisabled_color_gray:active
  > :global(.__wab_flex-container)
  > picture
  > img,
.rootisDisabled_color_gray:active
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.rootisDisabled_size_medium_color_primary:hover {
  padding: 0px;
}
.rootisDisabled_size_small_color_white:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled_size_medium_color_sky:active {
  padding: 0px;
}
.rootsize_large_isDisabled_color_primary:hover {
  padding: 14px 28px;
}
.rootcolor_cta_isDisabled_size_small:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled_color_white_size_medium:active {
  padding: 0px;
}
.rootsize_large_isDisabled_color_sky:active {
  padding: 14px 28px;
}
.rootisDisabled_color_white_size_medium:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_cta_isDisabled_size_medium:active {
  padding: 0px;
}
.rootsize_large_isDisabled_color_white:active {
  padding: 14px 28px;
}
.rootsize_large_isDisabled_color_white:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_cta_isDisabled_size_medium:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled_color_gray_size_medium:active {
  padding: 0px;
}
.rootcolor_cta_size_large_isDisabled:active {
  padding: 14px 28px;
}
.rootcolor_cta_size_large_isDisabled:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootsize_large_isDisabled_color_gray:active {
  padding: 14px 28px;
}
.startIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.slotTargetStartIcon {
  color: #ededec;
}
.slotTargetStartIconcolor_white {
  color: #35290f;
}
.svg___4Kh3L {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
}
.contentContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.contentContainersize_medium {
  width: auto;
  height: 40px;
  padding: 12px 24px;
}
.contentContainersize_medium > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.contentContainersize_medium > :global(.__wab_flex-container) > *,
.contentContainersize_medium
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.contentContainersize_medium > :global(.__wab_flex-container) > picture > img,
.contentContainersize_medium
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.slotTargetChildren {
  color: var(--token-9T9NC2lhv1Ej);
  font-weight: 500;
  white-space: pre;
  font-family: var(--rubik);
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
}
.slotTargetChildrenisDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetChildrensize_large {
  line-height: 20px;
  font-size: 15px;
}
.slotTargetChildrencolor_primary {
  color: #ffffff;
}
.slotTargetChildrencolor_sky {
  color: var(--token-vF1ggEL4NG72);
}
.slotTargetChildrencolor_white {
  color: var(--token-vF1ggEL4NG72);
}
.slotTargetChildrencolor_cta {
  color: #ffffff;
}
.slotTargetChildrencolor_gray {
  color: var(--token-x0ltlSxVUZco);
}
.slotTargetChildrenisDisabled_color_primary {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetChildrenisDisabled_color_sky {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetChildrenisDisabled_color_white {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetChildrencolor_cta_isDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetChildrenisDisabled_color_gray {
  color: var(--token-sJr9sbZwbyqK);
}
.rootcolor_primary:active .slotTargetChildrencolor_primary {
  color: var(--token-F-isEiSy-7QO);
}
.rootcolor_sky:active .slotTargetChildrencolor_sky {
  color: var(--token-gaowRD3VsD3w);
}
.rootcolor_white:hover .slotTargetChildrencolor_white {
  color: var(--token-pkN2y4iq-viG);
}
.rootcolor_white:active .slotTargetChildrencolor_white {
  color: var(--token-gaowRD3VsD3w);
}
.rootcolor_cta:active .slotTargetChildrencolor_cta {
  color: var(--token-WnhL5TAc8eef);
}
.rootcolor_gray:active .slotTargetChildrencolor_gray {
  color: var(--token-Uy2x1Cuw6kvC);
}
.rootisDisabled_color_primary:active
  .slotTargetChildrenisDisabled_color_primary {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_color_sky:active .slotTargetChildrenisDisabled_color_sky {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_color_white:active .slotTargetChildrenisDisabled_color_white {
  color: var(--token-sJr9sbZwbyqK);
}
.rootcolor_cta_isDisabled:active .slotTargetChildrencolor_cta_isDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_color_gray:active .slotTargetChildrenisDisabled_color_gray {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_size_small_color_white:hover
  .slotTargetChildrenisDisabled_size_small_color_white {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_color_white_size_medium:hover
  .slotTargetChildrenisDisabled_color_white_size_medium {
  color: var(--token-sJr9sbZwbyqK);
}
.rootsize_large_isDisabled_color_white:hover
  .slotTargetChildrensize_large_isDisabled_color_white {
  color: var(--token-sJr9sbZwbyqK);
}
.endIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.slotTargetEndIcon {
  color: #ededec;
}
.slotTargetEndIconcolor_white {
  color: #35290f;
}
.svg__hvE2J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
