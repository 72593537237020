.messageSentModal {
  background: url("https://site-assets.plasmic.app/06a802b107316a4f4f913be06e69535a.svg")
      top 470px left 50% / 1309px 403px no-repeat,
    linear-gradient(180deg, #149dd9 0%, #0271a2 100%) 0% 0% / 100% 100% repeat;
  width: 840px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: 0px 4px 32px -4px #0f2a4133, 0px 2px 8px -4px #0f2a4133;
  position: relative;
  min-height: 0;
  padding: 48px;
}
.messageSentModal > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.messageSentModal > :global(.__wab_flex-container) > *,
.messageSentModal > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.messageSentModal > :global(.__wab_flex-container) > picture > img,
.messageSentModal
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .messageSentModal {
    max-width: 840px;
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .messageSentModal > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .messageSentModal {
    width: 100%;
    background: linear-gradient(180deg, #149dd9 0%, #0271a2 100%) 0% 0% / 100%
      100% repeat;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .messageSentModal > :global(.__wab_flex-container) {
    justify-content: space-between;
    min-width: 0;
  }
}
.fill2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 480px;
  align-self: center;
}
.fill2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.fill2 > :global(.__wab_flex-container) > *,
.fill2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.fill2 > :global(.__wab_flex-container) > picture > img,
.fill2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .fill2 {
    max-width: 480px;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .fill2 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.icon {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 68px;
  height: 48px;
  flex-shrink: 0;
}
.icon > picture > img {
  object-fit: cover;
}
.text2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.text2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.text2 > :global(.__wab_flex-container) > *,
.text2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text2 > :global(.__wab_flex-container) > picture > img,
.text2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.h2__o6Lo0 {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-9T9NC2lhv1Ej);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  min-width: 0;
}
.h2__aJ2U8 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-9T9NC2lhv1Ej);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  min-width: 0;
}
.dialogClose:global(.__wab_instance) {
  position: relative;
  top: auto;
  right: auto;
  opacity: 1;
  max-width: 100%;
  object-fit: cover;
  border-radius: 999px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .dialogClose:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 128px;
  align-self: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button:global(.__wab_instance) {
    width: 100%;
    align-self: flex-end;
    min-width: 0;
  }
}
.svg__zh8TB {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__epBm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
